import { formIsInProgress, formIsRecordOfDetention } from "@aspire/common";
import { Box } from "@mui/material";
import { isEmpty } from "lodash-es";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Banner,
  BannerList,
  Dropdown,
  PopupDialog,
  PopupDialogTitle,
  TextField,
} from "~/components/design-system/index.js";
import { HorizontalLine } from "~/components/form/index.js";
import { PatientSearch, PatientSearchSchema } from "../PatientSearch.js";
import { ConfirmMerge } from "./ConfirmMerge.js";

import { useParams } from "react-router";
import { usePatientTimeline } from "~/hooks/apiCalls.js";

export function PatientMergeDialog({
  closeMergeDialog,
}: {
  closeMergeDialog: () => void;
}) {
  const { patientId } = useParams();
  const { patientTimeline } = usePatientTimeline({
    patientId: patientId,
  });

  const { t } = useTranslation();
  const [patientCreateState, setPatientCreateState] =
    React.useState<null | PatientSearchSchema>(null);

  const [selectedPatient, setSelectedPatient] = React.useState(null);

  const [reasonValueDropdown, setReasonValueDropdown] = React.useState(null);

  const [reasonDescription, setReasonDescription] = React.useState<
    string | undefined
  >(undefined);

  const reasonValues = [
    {
      label: "Patient record created in error",
      value: "patientCreatedInError",
    },
    {
      label: "Patient name unknown at time of form completion",
      value: "patientNameUnknown",
    },
    {
      label: "No access to primary patient at time of form completion",
      value: "noAccessToPrimaryPatient",
    },
    { label: "Patient Alias", value: "patientAlias" },
    {
      label: "Other",
      value: "other",
    },
  ];

  const isDropdownValueOther = reasonValueDropdown === "other";

  const showPatientSearch =
    (isDropdownValueOther && reasonDescription) ||
    (!isDropdownValueOther && reasonValueDropdown);

  const patientTimelineAdmissions = patientTimeline?.inProgressWork?.admissions;

  const hasStandaloneOrMultiFormsInProgress =
    patientTimeline?.inProgressWork?.items?.find((i) =>
      i.forms.find((i) => formIsInProgress(i)),
    );

  // This is checking that every admission within the form context has a completed h3 form, if it does we should treat the admission not in progress anymore
  const allAdmissionsComplete = (
    patientTimeline?.inProgressWork?.admissions || []
  ).every((admission) =>
    admission.forms.some(
      (form) => formIsRecordOfDetention(form) && form.status === "complete",
    ),
  );

  const hasFormInProgressForms =
    hasStandaloneOrMultiFormsInProgress ||
    (patientTimelineAdmissions &&
      patientTimelineAdmissions.length > 0 &&
      !allAdmissionsComplete);

  return (
    <>
      {isEmpty(selectedPatient) ? (
        <>
          <PopupDialog open={true} onClose={closeMergeDialog}>
            <PopupDialogTitle
              titleText={`Merge Patient ${
                patientTimeline?.patient.name.given || ""
              } ${patientTimeline?.patient.name.family || ""}`}
              closeDialog={closeMergeDialog}
            />
            <Box sx={{ mb: 2 }}>
              <Banner
                bannerType={BannerList.INFO}
                body={[
                  `${t(
                    "components.patientMergeDialog.mergeConfirmationMessageOne",
                  )}`,
                  `${t(
                    "components.patientMergeDialog.mergeConfirmationMessageTwo",
                  )}`,
                ]}
              />
            </Box>
            {hasFormInProgressForms && (
              <Box sx={{ mb: 2 }}>
                <Banner
                  bannerType={BannerList.WARNING}
                  title={t(
                    "components.patientMergeDialog.assessmentInProgressMessage",
                  )}
                />
              </Box>
            )}
            <Box sx={{ my: 2 }}>
              <Dropdown
                label={t("components.patientMergeDialog.reasonWhyMerge")}
                selectedValue={reasonValueDropdown}
                name={"invite"}
                values={reasonValues}
                onChange={(e) => {
                  setReasonValueDropdown(e);
                }}
              />
              {reasonValueDropdown === "other" && (
                <TextField
                  name={"reasonDescription"}
                  label={t(
                    "components.patientMergeDialog.reasonDescriptionText",
                  )}
                  onChange={(e) => setReasonDescription(e)}
                  value={reasonDescription || ""}
                  useFullWidth={true}
                />
              )}
            </Box>
            {showPatientSearch && (
              <>
                <HorizontalLine />
                <PatientSearch
                  patient={patientTimeline?.patient}
                  setPatientCreateState={setPatientCreateState}
                  isPatientMerge
                  setSelectedPatient={setSelectedPatient}
                />
              </>
            )}
          </PopupDialog>
        </>
      ) : (
        <>
          <ConfirmMerge
            currentPatient={patientTimeline?.patient!}
            selectedPatient={selectedPatient!}
            closeMergeDialog={closeMergeDialog}
            reason={
              reasonValues.find((item) => item.value === reasonValueDropdown)
                ?.label || ""
            }
            goBack={() => setSelectedPatient(null)}
            reasonDescription={reasonDescription}
          />
        </>
      )}
    </>
  );
}
