import { PatientIndexSearchResult } from "@aspire/common";
import { Box, useTheme } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { PatientCardExtended } from "~/components/design-system/index.js";
import { PatientSearchResultBanner } from "./SearchResultBanners.js";

export function PatientSearchResult({
  result,
  selectedId,
  setSelectedId,
  isPatientMerge,
}: {
  result: PatientIndexSearchResult;
  selectedId: string | null;
  setSelectedId: (value: string | null) => void;
  isPatientMerge?: boolean;
}) {
  const theme = useTheme();

  const { t } = useTranslation();

  function formatPatientDateOfBirth(dateOfBirth: string | null | undefined) {
    return dateOfBirth
      ? dayjs(dateOfBirth).format("DD/MM/YYYY")
      : t("common.unknown");
  }

  return (
    <>
      <Box sx={{ my: theme.spacing(4) }}>
        <PatientSearchResultBanner
          outcome={result.outcome}
          isPatientMerge={isPatientMerge}
        />
      </Box>

      {result.creatablePdsPatient && (
        <>
          <PatientCardExtended
            patientName={`${result.creatablePdsPatient.demographics.name.given}  ${result.creatablePdsPatient.demographics.name.family}`}
            patientDateOfBirth={formatPatientDateOfBirth(
              result.creatablePdsPatient.demographics.dateOfBirth,
            )}
            address={result.creatablePdsPatient.demographics.address.address}
            patientAge={dayjs()
              .diff(result.creatablePdsPatient.demographics.dateOfBirth, "year")
              .toString()}
            isSelected={selectedId === "pds"}
            setIsSelected={() =>
              setSelectedId(selectedId === "pds" ? null : "pds")
            }
          />
        </>
      )}
      {result.matchedPatients.map((patient) => {
        return (
          <Box sx={{ mb: 2 }}>
            <PatientCardExtended
              patientName={`${patient.name.given}  ${patient.name.family}`}
              patientDateOfBirth={formatPatientDateOfBirth(patient.dateOfBirth)}
              address={patient.address.address}
              patientAge={
                patient.dateOfBirth
                  ? dayjs().diff(patient.dateOfBirth, "year").toString()
                  : t("common.unknown")
              }
              patientId={patient.id}
              isSelected={selectedId === patient.id}
              setIsSelected={() =>
                setSelectedId(selectedId === patient.id ? null : patient.id)
              }
            />
          </Box>
        );
      })}
    </>
  );
}
