import {
  findFormsMerged,
  FormContextData,
  formHasSignatures,
  formIsCompleted,
  formIsInProgress,
  getLatestVersion,
  PatientTimelineResponse,
} from "@aspire/common";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FormFooterSection } from "~/components/form/index.js";
import { PatientBanner } from "~/components/layout/index.js";
import { useGetNhsNumber } from "~/hooks/ExternalPatientLink/useGetNhsNumber.js";
import { LoggedInUserContext } from "../../../Contexts.js";
import { routeFns } from "../../../routes.js";
import { PatientDemographicsOutdatedBanner } from "../../FormDraft/helpers/comparePatientDemographics.js";
import { FormContextPdfViewer } from "../helpers/FormContextPdfViewer.js";
import { ParticipantsList } from "../helpers/ParticipantsList.js";
import { WorkItemStatusCard } from "../helpers/WorkItemStatusCard.js";
import { StandaloneFormButtonActions } from "./StandaloneFormButtonActions.js";
import { StandaloneFormRow } from "./StandaloneFormRow.js";

export function StandaloneFormPage({
  reloadFormContext,
  formContext,
  patientTimeline,
  reloadPatientTimeline,
}: {
  formContext: FormContextData;
  reloadFormContext: () => void;
  patientTimeline: PatientTimelineResponse;
  reloadPatientTimeline: () => void;
}) {
  const userContext = React.useContext(LoggedInUserContext);
  const { user } = userContext!;
  const [pdfViewFormId, setPdfViewFormId] = useState<null | string>(null);

  const nhsNumber = useGetNhsNumber({ patientId: formContext.patientId });

  const { t } = useTranslation();

  const navigate = useNavigate();

  const canAssign =
    formContext.activeTeamworkWorkItem &&
    ["accepted", "completed"].includes(
      formContext.activeTeamworkWorkItem?.status,
    );

  const showFinaliseConfirmation = (fullFormName: string) => {
    navigate(
      routeFns.formContextPageConfirmFinaliseDialog(
        formContext.id,
        formContext.patientId,
        {
          itemName: fullFormName,
        },
      ),
    );
  };

  const showMhaStatusConfirmation = (fullFormName: string) => {
    navigate(
      routeFns.formContextPageEditMhaStatusDialog(
        formContext.id,
        formContext.patientId,
        {
          itemName: fullFormName,
        },
      ),
    );
  };

  const showDeleteConfirmation = () => {
    navigate(
      routeFns.formContextPageConfirmDeleteDraftDialog(
        formContext.id,
        formContext.patientId,
        { draftFormId: formContext.forms[0].id },
      ),
    );
  };

  const merged = findFormsMerged(
    patientTimeline?.mergedPatientData,
    formContext,
  );

  const hasInProgressForms = formContext.forms.some((f) => formIsInProgress(f));

  return (
    <Box
      data-testid="form-context:standalone"
      data-formcontextid={formContext.id}
    >
      {pdfViewFormId && (
        <FormContextPdfViewer
          inModal={true}
          forms={formContext.forms
            .filter(formHasSignatures)
            .filter((f) => formIsCompleted(f) || formIsInProgress(f))
            .sort((f1, f2) => (f1.updated < f2.updated ? 1 : -1))}
          setFormId={setPdfViewFormId}
          formId={pdfViewFormId}
        />
      )}

      <Box sx={{ mb: 3 }}>
        <PatientBanner
          patient={formContext.patient}
          nhsNumber={nhsNumber ?? undefined}
          disableSticky
          isHorizontalLineHidden
          patientTimeline={patientTimeline}
          reloadPatientTimeline={reloadPatientTimeline}
        />
      </Box>

      <WorkItemStatusCard
        formContext={formContext}
        reloadFormContext={reloadFormContext}
        user={user}
      />

      {formContext.activeTeamworkWorkItem &&
        getLatestVersion(formContext.forms[0]).data.length > 0 &&
        hasInProgressForms && (
          <PatientDemographicsOutdatedBanner
            patient={formContext.patient}
            currentValues={getLatestVersion(formContext.forms[0]).data[0].data}
          />
        )}

      {formContext.activeTeamworkWorkItem && (
        <StandaloneFormButtonActions
          formContext={formContext}
          user={user}
          showFinaliseConfirmation={showFinaliseConfirmation}
          showMhaStatusConfirmation={showMhaStatusConfirmation}
        />
      )}

      <StandaloneFormRow
        formContext={formContext}
        setPdfViewFormId={setPdfViewFormId}
        user={user}
        showDeleteConfirmation={showDeleteConfirmation}
        showFinaliseConfirmation={showFinaliseConfirmation}
        showMhaStatusConfirmation={showMhaStatusConfirmation}
        lastMerged={merged?.lastMerged}
      />

      <ParticipantsList formContext={formContext} />

      <FormFooterSection
        hideSubmit={!formContext.activeTeamworkWorkItem}
        isSticky={true}
        onSave={() => {
          navigate(
            routeFns.formContextPageSendRequest(
              formContext.id,
              formContext.patientId,
              formContext.forms[0].status === "in-progress"
                ? {
                    requestType: "complete",
                    formId: formContext.forms[0].id,
                    formPart: formContext.forms[0].versions
                      .at(-1)!
                      .data.length.toString(),
                  }
                : formContext.forms[0].status === "complete"
                  ? { requestType: "finalise" }
                  : {},
            ),
          );
        }}
        disableSubmit={!canAssign}
        saveLabel={t("buttonLabels.send")}
        discardLabel={t("buttonLabels.activityLog")}
        onCancel={() => {
          navigate(
            routeFns.formContextPageActivityLog(
              formContext.id,
              formContext.patientId,
            ),
          );
        }}
      />
    </Box>
  );
}
