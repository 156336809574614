import { ExtendedPatient, Patient } from "@aspire/common";
import {
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Banner,
  BannerList,
  FormTitle,
  PopupDialog,
  renderErrorToast,
  renderSuccessToast,
} from "~/components/design-system/index.js";
import { FormFooterSection } from "~/components/form/index.js";
import { CompactedPatientBanner } from "~/components/layout/index.js";
import { useGetNhsNumber } from "~/hooks/ExternalPatientLink/useGetNhsNumber.js";
import { api } from "../../../api.js";
import { useScreenDetection } from "../../../hooks/ScreenDetection/useScreenDetection.js";
import { routeFns } from "../../../routes.js";

export function ConfirmMerge({
  currentPatient,
  selectedPatient,
  closeMergeDialog,
  reason,
  reasonDescription,
  goBack,
}: {
  currentPatient: ExtendedPatient;
  selectedPatient: Patient;
  closeMergeDialog: (params: any) => void;
  reason: string;
  reasonDescription?: string;
  goBack: () => void;
}) {
  const { t } = useTranslation();
  const [animateArrow, setAnimateArrow] = useState(false);
  const theme = useTheme();

  const navigate = useNavigate();

  useEffect(() => {
    setAnimateArrow(true);
    return () => setAnimateArrow(false);
  }, []);

  const { isMobileView } = useScreenDetection();

  const currentPatientNhsNumber = useGetNhsNumber({
    patientId: currentPatient.id,
  });
  const selectedPatientNhsNumber = useGetNhsNumber({
    patientId: selectedPatient.id,
  });

  return (
    <>
      <PopupDialog open={true} onClose={closeMergeDialog}>
        <FormTitle
          useReducedTopPadding={true}
          hasTitleBottomMargin={false}
          titleText={t("components.patientMergeDialog.confirmYourAction")}
        />
        <Box sx={{ mb: 2 }}>
          <Banner
            bannerType={BannerList.WARNING}
            title={t("components.patientMergeDialog.mergeThesePatients")}
          />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          flexDirection={isMobileView ? "column" : "row"}
        >
          <Box display="flex" flexDirection="column" width="100%">
            <Typography fontWeight="bold" sx={{ my: 1, ml: 1.875 }}>
              {t("components.patientMergeDialog.patientRemoved")}
            </Typography>
            <Box sx={{ border: "1px solid #FF3D00", borderRadius: "5px" }}>
              <CompactedPatientBanner
                patient={currentPatient}
                nhsNumber={currentPatientNhsNumber ?? undefined}
                isHidden
              />
            </Box>
          </Box>
          {isMobileView ? (
            <KeyboardDoubleArrowDown
              fontSize="large"
              sx={{
                mx: 2,
                opacity: animateArrow ? 1 : 0,
                transition: "opacity 1s ease-in-out, transform 1s ease-in-out",
                transform: animateArrow ? "translateX(0)" : "translateX(-50px)",
              }}
            />
          ) : (
            <>
              <KeyboardDoubleArrowRight
                fontSize="large"
                sx={{
                  mx: 2,
                  opacity: animateArrow ? 1 : 0,
                  transition:
                    "opacity 1s ease-in-out, transform 1s ease-in-out",
                  transform: animateArrow
                    ? "translateX(0)"
                    : "translateX(-50px)",
                }}
              />
            </>
          )}

          <Box display="flex" flexDirection="column" width="100%">
            <Typography fontWeight="bold" sx={{ my: 1, ml: 1.875 }}>
              {t("components.patientMergeDialog.remainPrimaryPatient")}
            </Typography>
            <Box
              sx={{
                border: `1px solid ${theme.palette.common.limeGreen}`,
                borderRadius: "5px",
              }}
            >
              <CompactedPatientBanner
                patient={selectedPatient}
                nhsNumber={selectedPatientNhsNumber ?? undefined}
                isHidden
              />
            </Box>
          </Box>
        </Box>

        <FormFooterSection
          customFooterBackgroundColor={theme.palette.common.white}
          onSave={async () => {
            const result = await api.patients.merge({
              currentPatientId: currentPatient.id,
              targetPatientId: selectedPatient.id,
              reason,
              reasonDescription,
            });

            if (result.status === 204) {
              const selectedPatientName = selectedPatient.name;
              renderSuccessToast({
                message: `Successfully merged ${
                  currentPatient.name.given || ""
                } ${currentPatient.name.family} into ${
                  selectedPatientName.given
                } ${selectedPatientName.family}`,
              });
              navigate(routeFns.patientHome(selectedPatient.id));
            } else {
              renderErrorToast({
                message: `Failed to merge - ${result.data.reason}`,
              });
            }
            closeMergeDialog(true);
          }}
          performSaveActionOnFormSubmit
          saveLabel={t("buttonLabels.confirm")}
          saveVariant="contained"
          secondaryLeftButton={<></>}
          discardLabel={t("buttonLabels.goBack")}
          onCancel={goBack}
          disableSubmit={false}
        />
      </PopupDialog>
    </>
  );
}
