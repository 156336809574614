import { PatientIndexSearchResult } from "@aspire/common";
import { Stack } from "@mui/material";
import { useDialogs } from "@toolpad/core/useDialogs";
import dayjs from "dayjs";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import { api } from "~/api.js";
import { LoggedInUserContext } from "~/Contexts.js";
import { routeFns } from "~/routes.js";
import { CollectCriteria } from "./CollectCriteria.js";
import {
  CollectCriteriaDemographicsProps,
  ValidationSchema as ValidationSchemaDemographics,
} from "./CollectCriteriaDemographics.js";
import { CollectCriteriaNhsNumberProps } from "./CollectCriteriaNhsNumber.js";
import { CreatePatient } from "./CreatePatient.js";
import { Footer } from "./Footer.js";
import { Header } from "./Header.js";
import { SearchReasoningDialog } from "./SearchReasoningDialog.js";
import { SearchResults } from "./SearchResults.js";

export type PatientSearchProps = Record<string, never>;

/**
 * Contains a set of search results, and the handlers to act upon them.
 * This combination allows us to have different journeys depending on
 * whether the search was done by demographics or NHS Number.
 */
type SearchResultsAndHandlers = {
  searchResults: "loading" | PatientIndexSearchResult;

  /**
   * The function we will call if we want to continue with a search result.
   * @param index The index of the selected search result.
   * @returns
   */
  onContinue?: (index: number) => void;

  /**
   * Called when the user wants to create a patient.
   * This should return the demographics to pre-fill the create patient form with.
   * @returns The demographics to pre-fill the create patient form with.
   */
  onCreate?: () => ValidationSchemaDemographics | undefined;
};

export const PatientSearch = ({}: PatientSearchProps) => {
  const dialogs = useDialogs();
  const navigate = useNavigate();

  const canSearchByNhsNumber = useSearchByNhsNumberEnabled();
  const mustRecordSearchReason = useRecordSearchReasonEnabled();
  const rioInstance = useRioInstance();

  // Whether we are creating a patient having failed to find one.
  const [isCreatingPatient, setIsCreatingPatient] = useState(false);

  // This is where we store the search result from the API
  const [searchResultsAndHandlers, setSearchResultsAndHandlers] = useState<
    SearchResultsAndHandlers | undefined
  >(undefined);

  // This is where we store the index of the selected search
  const [searchResultIndexSelected, setSearchResultIndexSelected] = useState<
    number | null
  >(null);

  // Calculate the overall state of the page
  const state =
    searchResultsAndHandlers === undefined
      ? "search"
      : searchResultsAndHandlers.searchResults === "loading"
        ? "searching"
        : searchResultsAndHandlers.searchResults.matchedPatients.length === 0
          ? "results-not-found"
          : searchResultIndexSelected === null
            ? "results-found"
            : "result-selected";

  // Search for patients using NHS Number
  const searchByNhsNumber: CollectCriteriaNhsNumberProps["onSubmit"] = async (
    values,
  ) => {
    setSearchResultsAndHandlers({ searchResults: "loading" });

    // Look for an existing aspire patient with this NHS Number
    const { data } = await api.patients.search(
      {
        type: "nhs-number",
        nhsNumber: values.nhsNumber,
      },
      "local",
    );

    // Look in Rio
    // TODO: This just redirects us onto a clickthrough journey.
    //       This is not how we will want this to work when we do PDS as well.
    //       We should do a proper search in Rio here, present the results in-situ,
    //       and if they fail continue with a PDS search.
    if (data.matchedPatients.length === 0 && rioInstance) {
      // Create a claimed click-through token
      const token = v4();
      const tokenResponse = await api.rio.clickThrough.createAndClaimToken({
        token,
        externalSystemType: "rio",
        externalSystemId: rioInstance.instanceId,
        externalPatientIdType: "nhs.number",
        externalPatientIdValue: values.nhsNumber,
      });

      if (tokenResponse.status === 204) {
        // Redirect to the click-through flow to redeem the token and continue the journey
        navigate(routeFns.patientCreateFromExternalId(token));
      }
    }

    setSearchResultsAndHandlers({
      searchResults: data,
      onContinue: async (index) => {
        const selectedPatientId = data.matchedPatients[index].id;

        if (mustRecordSearchReason) {
          const response = await dialogs.open(SearchReasoningDialog);
          if (response.type === "cancelled") {
            return;
          }

          // Record the search reason
          const _result = await api.patients.addSearchReasoning({
            reason: response.reasoning.reason,
            reasonDescription:
              response.reasoning.reason === "other"
                ? response.reasoning.description
                : undefined,
            selectedPatientId: selectedPatientId,
          });
        }

        // Fetch our Rio links for this patient
        const externalLinks = (
          await api.patients.getExternalPatientLinks(selectedPatientId)
        ).data.externalLinks.filter(
          (link) =>
            link.externalSystemType === "rio" &&
            link.externalSystemId === rioInstance?.instanceId,
        );

        if (externalLinks.length > 0) {
          const externalLink = externalLinks[0];

          // Do a click-through to review the external patient record
          const token = v4();
          const tokenResponse = await api.rio.clickThrough.createAndClaimToken({
            token,
            externalSystemType: externalLink.externalSystemType,
            externalSystemId: externalLink.externalSystemId,
            externalPatientIdType: externalLink.externalPatientIdType,
            externalPatientIdValue: externalLink.externalPatientIdValue,
          });

          if (tokenResponse.status === 204) {
            // Redirect to the click-through flow to redeem the token and continue the journey
            navigate(`/patients/context-launch?token=${token}`);
            return;
          }
        }

        // Redirect to the patient page
        navigate(routeFns.patientHome(selectedPatientId));
      },
      onCreate: () => {
        // We don't have any information about demographics to
        // pre-fill the create patient form with.
        return undefined;
      },
    });
  };

  // Search for patients using Demographics
  const searchByDemographics: CollectCriteriaDemographicsProps["onSubmit"] =
    async (values) => {
      setSearchResultsAndHandlers({ searchResults: "loading" });

      const { data } = await api.patients.search(
        {
          type: "demographics",
          query: {
            given: values.givenName ?? undefined,
            family: values.familyName!,
            birthdate: values.dateOfBirthIsExact
              ? values.dateOfBirthExact
              : ageRangeToDateRange(values.ageApprox),
          },
        },
        "local",
      );

      setSearchResultsAndHandlers({
        searchResults: data,
        onContinue: async (index) => {
          const selectedPatientId = data.matchedPatients[index].id;

          if (mustRecordSearchReason) {
            const response = await dialogs.open(SearchReasoningDialog);
            if (response.type === "cancelled") {
              return;
            }

            // Record the search reason
            const _result = await api.patients.addSearchReasoning({
              reason: response.reasoning.reason,
              reasonDescription:
                response.reasoning.reason === "other"
                  ? response.reasoning.description
                  : undefined,
              selectedPatientId,
            });
          }

          // Fetch our Rio links for this patient
          const externalLinks = (
            await api.patients.getExternalPatientLinks(selectedPatientId)
          ).data.externalLinks.filter(
            (link) =>
              link.externalSystemType === "rio" &&
              link.externalSystemId === rioInstance?.instanceId,
          );

          if (externalLinks.length > 0) {
            const externalLink = externalLinks[0];

            // Do a click-through to review the external patient record
            const token = v4();
            const tokenResponse =
              await api.rio.clickThrough.createAndClaimToken({
                token,
                externalSystemType: externalLink.externalSystemType,
                externalSystemId: externalLink.externalSystemId,
                externalPatientIdType: externalLink.externalPatientIdType,
                externalPatientIdValue: externalLink.externalPatientIdValue,
              });

            if (tokenResponse.status === 204) {
              // Redirect to the click-through flow to redeem the token and continue the journey
              navigate(`/patients/context-launch?token=${token}`);
              return;
            }
          }

          // Redirect to the patient page
          navigate(routeFns.patientHome(selectedPatientId));
        },
        onCreate: () => {
          // We will use these demographics to pre-fill the create
          // patient form with, if they are not happy with the search results.
          return values;
        },
      });
    };

  // Show the create patient page
  const onCreate = async () => {
    setIsCreatingPatient(true);
  };

  return isCreatingPatient ? (
    <CreatePatient
      initialValues={searchResultsAndHandlers?.onCreate?.()}
      onCancel={() => {
        setSearchResultsAndHandlers(undefined);
        setSearchResultIndexSelected(null);
        setIsCreatingPatient(false);
      }}
    />
  ) : (
    <Stack gap="1rem">
      <Header />

      <CollectCriteria
        canSearchByNhsNumber={canSearchByNhsNumber}
        onSubmitNhsNumber={searchByNhsNumber}
        onSubmitDemographics={searchByDemographics}
      >
        {({ onClear }) => {
          return (
            <>
              <SearchResults
                searchResult={searchResultsAndHandlers?.searchResults}
                onSelectedIndexChanged={setSearchResultIndexSelected}
              />

              <Footer
                state={state}
                onContinue={() => {
                  if (searchResultIndexSelected !== null)
                    searchResultsAndHandlers?.onContinue?.(
                      searchResultIndexSelected,
                    );
                }}
                onClear={() => {
                  // Clear the Formik fields
                  onClear();

                  // Clear the previous search results
                  setSearchResultsAndHandlers(undefined);
                  setSearchResultIndexSelected(null);
                }}
                onCreate={onCreate}
              />
            </>
          );
        }}
      </CollectCriteria>
    </Stack>
  );
};

const ageRangeToDateRange = (ages: string | null) => {
  const parsedAges = ages === null ? null : JSON.parse(ages);
  if (
    parsedAges === null ||
    (parsedAges.min === undefined && parsedAges.max === undefined)
  ) {
    return null;
  }

  const startDate = dayjs()
    .subtract(parsedAges.max ?? 120, "years")
    .format("YYYY-MM-DD");
  const endDate = dayjs()
    .subtract(parsedAges.min ?? 0, "years")
    .format("YYYY-MM-DD");

  return [startDate, endDate];
};

const useSearchByNhsNumberEnabled = () => {
  const userContext = useContext(LoggedInUserContext);

  return (
    userContext?.user.sessionOrganisationConfiguration?.nhsNumberEnabled ?? true // TODO: What is the correct default?
  );
};

const useRecordSearchReasonEnabled = () => {
  const userContext = useContext(LoggedInUserContext);

  return (
    userContext?.user.sessionOrganisationConfiguration
      ?.patientSearchRecordReasonsEnabled ?? true // TODO: What is the correct default?
  );
};

const useRioInstance = () => {
  const userContext = useContext(LoggedInUserContext);

  return userContext?.user?.sessionOrganisationConfiguration
    ?.rioInstancesEnabled?.[0];
};
