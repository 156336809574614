import {
  applicationTemplateIds,
  FormContextData,
  formHasSignatures,
  formIsCompleted,
  formIsRecordOfDetention,
  getBaseFormTemplate,
  getFullFormName,
  getLatestVersion,
  H3Data,
  recordOfDetentionTemplateIds,
} from "@aspire/common";
import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { v4 } from "uuid";
import {
  Banner,
  BannerList,
  renderErrorToast,
  renderSuccessToast,
} from "~/components/design-system/index.js";
import { FormFooterSection } from "~/components/form/index.js";
import { PatientBanner } from "~/components/layout/index.js";
import { useGetNhsNumber } from "~/hooks/ExternalPatientLink/useGetNhsNumber.js";
import { api, apiHooks } from "../api.js";
import { usePatientTimeline } from "../hooks/apiCalls.js";
import { routeFns } from "../routes.js";
import { DefaultPageProps } from "./defaultProps.js";
import { RecordReasonDialog } from "./FormProgressPage/admission/RecordReasonDialog.js";
import {
  FormContextPdfViewer,
  VersionFormsPdfViewer,
} from "./FormProgressPage/helpers/FormContextPdfViewer.js";

export function reviewableForms(
  formImWorkingOn: string | undefined,
  formContext: FormContextData,
) {
  const hasCompletedApplication = formContext.forms.some(
    (f) =>
      applicationTemplateIds.includes(f.formTemplate.id) &&
      ["complete", "finalised"].includes(f.status),
  );

  return formContext.forms
    .filter((f) => getLatestVersion(f).signatures.length > 0)
    .filter(
      (f) =>
        // The form is the form on your work item (e.g. the form you're being asked to review, which may be a
        // med rec being completed after the application has been signed)
        f.id === formImWorkingOn ||
        // Don't filter any forms out if there's no completed application
        !hasCompletedApplication ||
        // Don't filter out any forms with links
        f.linkedForms.length > 0 ||
        // Don't filter out applications
        applicationTemplateIds.includes(f.formTemplate.id) ||
        // Don't filter out records of detention
        recordOfDetentionTemplateIds.includes(f.formTemplate.id),
    )
    .sort((f1, f2) => (f1.updated < f2.updated ? 1 : -1));
}

function getBannerTitle(
  fullFormName: string | null,
  formContext: FormContextData,
  formId: string | undefined,
  numOfFormParts: number | undefined,
) {
  const activeWorkItem = formContext.activeTeamworkWorkItem;
  const createdByName = `${activeWorkItem?.createdBy.userName} (${activeWorkItem?.createdBy.teamName})`;

  const matchingForm = activeWorkItem?.forms.find(
    (f) => f.formTemplate.id === formId,
  );
  const formHasOnePart = numOfFormParts === 1;
  const partMessage = !formHasOnePart
    ? ` part ${activeWorkItem?.formPart ?? ""} of `
    : "";

  // Admission
  if (!fullFormName) {
    return `${createdByName} has invited you to access the above patient's assessment documents.`;
  }

  if (activeWorkItem?.requestType === "amend") {
    const formattedReasons = activeWorkItem.requestReasons
      ?.map((r) => r.label)
      ?.join(", ");
    const h3Form = formContext.forms.find(
      (f) => formHasSignatures(f) && formIsRecordOfDetention(f),
    );
    const section = (getLatestVersion(h3Form)?.data as H3Data)?.[0]?.data
      .sectionValue;
    const timePeriod = section === "4" ? "72 hours" : "14 days";
    return [
      `${createdByName} has requested ${partMessage}${fullFormName} to be amended under Section 15 of the Act.`,
      `Reason(s): ${formattedReasons || ""}`,
      `Description: ${activeWorkItem.requestAdditionalInfo || ""}`,
      `The form can be amended within ${timePeriod} after admission if the form is found to be incorrect or defective. Please note that certain errors may invalidate the patient’s detention and cannot be corrected, a new form must be provided in these circumstances. Click accept if you need to amend this form. Click decline if you do not indent to amend this form.`,
    ];
  }

  // Standalone Form
  if (activeWorkItem?.requestType === "finalise") {
    // Don't mention 'completing' a form for MHA team finalisation requests
    return `${createdByName} has invited you to complete administrative scrutiny for ${fullFormName}. Click accept to scrutinise this form. Click decline if you do not intend to scrutinise the form.`;
  }

  if (activeWorkItem?.requestType === "complete") {
    return `${createdByName} has invited you to complete${partMessage} ${fullFormName}. Click accept to complete the form. Click decline if you do not intend to complete the form.`;
  }

  if (matchingForm && formIsCompleted(matchingForm)) {
    return `${createdByName} has invited you to access ${fullFormName}. Click accept to access form. Click decline if you do not intend to access the form.`;
  }
  return `${createdByName} has invited you to complete ${fullFormName}. Click accept to complete form. Click decline if you do not intend to complete the form`;
}

const amendDeclineReasons = [
  { value: "I believe this was sent in error" },
  {
    value: "Details on the form are correct",
    descriptionPrompt: "Please state the correct information",
  },
  {
    value: "Other",
    descriptionPrompt: "Please describe your reason",
  },
].map((o) => ({ ...o, label: o.value }));

const admissionDeclineOptions = [
  { value: "I believe this was sent in error" },
  {
    value: "Details on the form are incorrect",
    descriptionPrompt: "Please state the incorrect information",
  },
  {
    value: "Other",
    descriptionPrompt: "Please describe your reason",
  },
].map((o) => ({ ...o, label: o.value }));

const standaloneFormDeclineOptions = [
  { value: "I do not believe this is clinically appropriate" },
  { value: "I believe this was sent in error" },
  {
    value: "Details on the form are incorrect",
    descriptionPrompt: "Please state the incorrect information",
  },
  {
    value: "Other",
    descriptionPrompt: "Please describe your reason",
  },
].map((o) => ({ ...o, label: o.value }));

function FormReviewPageInner({
  formContext,
}: {
  formContext: FormContextData;
}) {
  const navigate = useNavigate();
  const [rejectionDialogOpen, setRejectionDialogOpen] = React.useState(false);

  const { patientTimeline, reloadPatientTimeline } = usePatientTimeline({
    patientId: formContext.patientId,
  });

  const nhsNumber = useGetNhsNumber({ patientId: formContext.patientId });

  const myWorkItem = formContext.activeTeamworkWorkItem;
  const form =
    formContext.type === "standalone"
      ? formContext.forms[0]
      : myWorkItem?.formId
        ? formContext.forms.find((f) => f.id === myWorkItem?.formId)
        : null;
  const template = form ? getBaseFormTemplate(form) : null;
  const fullFormName = getFullFormName(template);
  const numOfFormParts = template?.parts.length;

  const completedForms = reviewableForms(myWorkItem?.formId, formContext);

  const hasCompletedForms = completedForms.length > 0;

  const [isPdfViewOpen, setIsPdfViewOpen] = React.useState<string | null>(
    myWorkItem?.formId || (hasCompletedForms ? completedForms[0].id : null),
  );

  const isAmendRequestType = myWorkItem?.requestType === "amend";

  const options = isAmendRequestType
    ? amendDeclineReasons
    : formContext.type === "standalone"
      ? standaloneFormDeclineOptions
      : admissionDeclineOptions;

  return (
    <>
      {rejectionDialogOpen && (
        <RecordReasonDialog
          title="Decline Invite"
          dropDownLabel="Enter a reason why you have chosen to decline this invitation"
          options={options}
          onClose={() => setRejectionDialogOpen(false)}
          onConfirmClick={async (reason, reasonDescription) => {
            const result = await api.work.reject(
              myWorkItem!.id,
              reason,
              reasonDescription,
            );
            if (result.status === 204) {
              renderSuccessToast({
                message: `Request declined and sent back to ${myWorkItem?.createdBy.userName} (${myWorkItem?.createdBy.teamName})`,
              });
              navigate(
                routeFns.formContextPageSuccessDialog(
                  formContext.id,
                  formContext.patientId,
                  isAmendRequestType
                    ? {
                        title: "Request declined ",
                        message: `You have successfully declined the request to amend a form under Section 15 of the Act. The requesting team has been notified by email of your decision.`,
                      }
                    : {
                        title: "Invite declined ",
                        message: `You have successfully declined the invitation to work on the patient's assessment documents. The requesting team has been notified by email of your decision.`,
                      },
                ),
              );
            } else {
              renderErrorToast({ message: "Failed to decline request" });
            }
          }}
        />
      )}
      <PatientBanner
        patient={formContext.patient}
        patientTimeline={patientTimeline}
        nhsNumber={nhsNumber ?? undefined}
        reloadPatientTimeline={reloadPatientTimeline}
      />
      <Box sx={{ mb: 2 }}>
        <Banner
          body={getBannerTitle(
            fullFormName,
            formContext,
            template?.id,
            numOfFormParts,
          )}
          bannerType={BannerList.WARNING}
        />
      </Box>
      {isAmendRequestType && isPdfViewOpen && hasCompletedForms ? (
        <VersionFormsPdfViewer
          inModal={false}
          forms={completedForms}
          setFormId={setIsPdfViewOpen}
          formId={isPdfViewOpen}
          showPrevNextButtons={false}
        />
      ) : (
        <>
          {isPdfViewOpen && hasCompletedForms && (
            <Box
              sx={{ display: "flex", width: "100%", justifyContent: "center" }}
            >
              <Box sx={{ width: "750px" }}>
                <FormContextPdfViewer
                  inModal={false}
                  forms={completedForms}
                  setFormId={setIsPdfViewOpen}
                  formId={isPdfViewOpen}
                />
              </Box>
            </Box>
          )}
        </>
      )}

      <FormFooterSection
        isSticky={true}
        isForcedSticky={true}
        saveLabel={"Accept"}
        discardLabel={"Decline"}
        onSave={async () => {
          await api.work.accept(myWorkItem!.id);
          if (
            myWorkItem?.requestType === "complete" ||
            myWorkItem?.requestType === "amend"
          ) {
            const draftId = v4();
            const result = await api.drafts.create(
              draftId,
              // myWorkItem.formId will always be defined for a "complete" or "amend" request
              { formId: myWorkItem.formId! },
            );
            if (result.status === 201) {
              navigate(
                routeFns.formDraftsComplete(draftId, formContext.patientId),
              );
            } else {
              navigate(
                routeFns.formContextPage(formContext.id, formContext.patientId),
              );
            }
          } else {
            navigate(
              routeFns.formContextPage(formContext.id, formContext.patientId),
            );
          }
        }}
        onCancel={() => {
          setRejectionDialogOpen(true);
        }}
      />
    </>
  );
}

function validateFormIsReadyForReview(
  formContext: FormContextData | undefined | null,
  formLoading: boolean,
  navigate: NavigateFunction,
) {
  if (!formLoading) {
    if (!formContext) {
      navigate(routeFns.notFound(), { replace: true });
    } else {
      if (
        !formContext.activeTeamworkWorkItem ||
        formContext.activeTeamworkWorkItem.status !== "pending"
      ) {
        navigate(
          routeFns.formContextPage(formContext.id, formContext.patientId),
        );
      }
    }
  }
}

export function FormReviewPage({}: DefaultPageProps) {
  let { formContextId } = useParams();
  const navigate = useNavigate();

  const [{ data: formContext, loading: formLoading }] =
    apiHooks.forms.getFormContext(formContextId!);

  useEffect(
    () => validateFormIsReadyForReview(formContext, formLoading, navigate),
    [formContext, formLoading, navigate],
  );

  return formLoading ? (
    <></>
  ) : (
    <FormReviewPageInner formContext={formContext!} />
  );
}
